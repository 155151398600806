/* ==============
  Calendar
===================*/

.fc {
    .fc-toolbar {
        h2 {
            font-size: 16px;
            line-height: 30px;
            text-transform: uppercase;
        }

        @media (max-width: 767.98px) {
            .fc-left,
            .fc-right,
            .fc-center {
                float: none;
                display: block;
                text-align: center;
                clear: both;
                margin: 10px 0;
            }

            > * > * {
                float: none;
            }

            .fc-today-button {
                display: none;
            }
        }

        .btn {
            text-transform: capitalize;
        }
    }
}

.fc {
    th.fc-widget-header {
        background: $gray-300;
        color: $gray-700;
        line-height: 20px;
        padding: 10px 0;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}

.fc-unthemed {
    .fc-content,
    .fc-divider,
    .fc-list-heading td,
    .fc-list-view,
    .fc-popover,
    .fc-row,
    tbody,
    td,
    th,
    thead {
        border-color: $gray-300;
    }

    td.fc-today {
        background: lighten($gray-200, 2%);
    }
}

.fc-button {
    background: $card-bg;
    border-color: $border-color;
    color: $gray-700;
    text-transform: capitalize;
    box-shadow: none;
    padding: 6px 12px !important;
    height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: $primary;
    color: $white;
    text-shadow: none;
}

.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 0.8125rem;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
}

.fc-event,
.fc-event-dot {
    background-color: $primary;
}

.fc-event .fc-content {
    color: $white;
}

.fc-day-header {
    font-weight: $font-weight-medium;
}

.fc-day-grid-event {
    .fc-time {
        font-weight: $font-weight-medium;
    }
    &.bg-dark {
        background-color: $black !important;
    }
}

#external-events {
    .external-event {
        text-align: left !important;
        padding: 8px 16px;
    }
}

.fc .fc-button-primary {
    background-color: var(--#{$prefix}primary) !important;
    border-color: var(--#{$prefix}primary) !important;
    box-shadow: none !important;
}

.fc-daygrid-event-dot {
    display: none !important;
}

.fc-event {
    justify-content: center;
    &.fc-event-draggable {
        text-align: center;
        color: $white !important;
    }

    .fc-event-title {
        flex-grow: 0 !important;
        font-weight: 400 !important;
    }
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: $info-bg-subtle !important;
}

.fc-scrollgrid {
    border-color: var(--#{$prefix}border-color) !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
    border-color: var(--#{$prefix}border-color) !important;
}

.fc .fc-list-sticky .fc-list-day > * {
    background-color: var(--#{$prefix}secondary-bg) !important;
}

.fc-theme-standard .fc-list {
    border-color: var(--#{$prefix}border-color) !important;
}
// RTL
[dir="rtl"] .fc-header-toolbar {
    direction: ltr !important;
}

[dir="rtl"] .fc-toolbar > * > :not(:first-child) {
    margin-left: 0.75em;
}