

/* Flot chart */
.flot-charts-height {
  height: 320px;
}

.flotTip {
  padding: 8px 12px;
  background-color: rgba(var(--#{$prefix}dark-rgb), 0.9);
  z-index: 100;
  color: var(--#{$prefix}white);
  box-shadow: var(--#{$prefix}box-shadow);
  border-radius: 4px;
}

.legendLabel{
  color: $gray-500;
}